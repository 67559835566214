import * as PharmacyUpdateActions from '../actions/update.configuration.actions';

const initialState = {
  loading: false,
  submitSuccess: false,
  data: {
    dispensingSystem: 0,
    fredPakStatusActive: 'true',
    minfosServerURL: '',
    minfosUserName: '',
    minfosPassword: '',
    minfosBusinessCode: '',
    lotsSQLHost: '',
    lotsSQLDatabase: '',
    lotsSQLUserName: '',
    lotsSQLPassword: '',
    zsqlHost: '',
    zsqlDatabase: '',
    zsqlUserName: '',
    zsqlPassword: '',
    corumClearConnectPath: '',
    corumClearIdentifier: '',
    fredDataBaseFolder: '',
    pathForDORPTS: '',
    fredPakPath: '',
    pharmacyGuid: '',
    syncCollection: [],
    maxDaysToCheckScript: 0,
    packJobPrinting: false,
    packJobPacking: false,
    packJobChecking: false,
    packJobDistribution: false,
    zapiServiceURL: '',
    zapiVendorAccessKey: '',
    zapiStoreAccessKey: '',
    zapiUserName: '',
    zapiBatchId: '',
    fredNXTAPIURL: '',
    fredNXTAccessKey: '',
    fredNXTSubscriptionKey: '',
    enableQScript: 'true',
    enableVPB: 'true',
    enableDispenseTracker: 'true',
    isDoseAdd: 'true',
    dwDatabase: '',
    dwHost: '',
    dwTrustedConnection: false,
    dwUserName: '',
    dwPassword: '',
    dwqScriptExportFolder: '',
    daysImportNewDrugsDispensed: 0,
    fredDispensePlusApiKey: '',
    fredDispensePlusURL: '',
    fredDispensePlusSubscriptionKey: '',
    logoutInactivityTime: 30,
    collapseMenuBar: false,
  },
  error: null,
  message: '',
};

const configuration = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case PharmacyUpdateActions.GET_PHARMACY_CONFIGURATION:
			return { ...state, error: null, message: '', loading: true };
		case PharmacyUpdateActions.GET_PHARMACY_CONFIGURATION_SUCCESS:
			return { ...state, loading: false, ...payload };
		case PharmacyUpdateActions.GET_PHARMACY_CONFIGURATION_FAIL:
			return { ...state, loading: false, message: payload.message, error: payload.error };
		case PharmacyUpdateActions.UPDATE_PHARMACY_CONFIGURATION:
			return { ...state, loading: true, submitSuccess: false, error: null, message: '' };
		case PharmacyUpdateActions.UPDATE_PHARMACY_CONFIGURATION_SUCCESS:
			return { ...state, loading: false, submitSuccess: true };
		case PharmacyUpdateActions.UPDATE_PHARMACY_CONFIGURATION_FAIL:
			return { ...state, loading: false, message: payload.message, error: payload.error };
		case PharmacyUpdateActions.CHANGE_PHARMACY_CONFIGURATION_FORM:
			return { ...state, data: { ...state.data, ...payload.data } };
		case PharmacyUpdateActions.CLEAR_PHARMACY_CONFIGURATION_FORM:
			return initialState;
		default:
			return state;
	}
};

export default configuration;
