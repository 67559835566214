import { find, includes, toNumber } from 'lodash';
import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';
import i18n from 'i18n';

export const PACK_GET_DOSE = '@JOB_PACK/GET_DOSE';
export const PACK_GET_DOSE_SUCCESS = '@JOB_PACK/GET_DOSE_SUCCESS';
export const PACK_GET_DOSE_FAIL = '@JOB_PACK/GET_DOSE_FAIL';

export const PACK_SHOW_PRESCRIPTION = '@JOB_PACK/PACK_SHOW_PRESCRIPTION';

export const PACK_SEARCH_BY_APN = '@JOB_PACK/PACK_SEARCH_BY_APN';
export const PACK_SEARCH_BY_APN_SUCCESS = '@JOB_PACK/PACK_SEARCH_BY_APN_SUCCESS';
export const PACK_SEARCH_BY_APN_FAIL = '@JOB_PACK/PACK_SEARCH_BY_APN_FAIL';

export const PACK_RECORD_VPB = '@JOB_PACK/PACK_RECORD_VPB';
export const PACK_RECORD_VPB_SUCCESS = '@JOB_PACK/PACK_RECORD_VPB_SUCCESS';
export const PACK_RECORD_VPB_FAIL = '@JOB_PACK/PACK_RECORD_VPB_FAIL';

export const PACK_UPDATE_MATCH_APN_ID = '@JOB_PACK/PACK_UPDATE_MATCH_APN_ID';

export const PACK_RESET_DATA = '@JOB_PACK/PACK_RESET_DATA';

export const PACK_GET_PRINTER_SETTING = '@JOB_PACK/PACK_GET_PRINTER_SETTING';
export const PACK_GET_PRINTER_SETTING_SUCCESS = '@JOB_PACK/PACK_GET_PRINTER_SETTING_SUCCESS';
export const PACK_GET_PRINTER_SETTING_FAIL = '@JOB_PACK/PACK_GET_PRINTER_SETTING_FAIL';

export const PACK_CONFIRM_PRESCRIPTION = '@JOB_PACK/PACK_CONFIRM_PRESCRIPTION';
export const PACK_CONFIRM_PRESCRIPTION_SUCCESS = '@JOB_PACK/PACK_CONFIRM_PRESCRIPTION_SUCCESS';
export const PACK_CONFIRM_PRESCRIPTION_FAIL = '@JOB_PACK/PACK_CONFIRM_PRESCRIPTION_FAIL';

export const PRINTING = '@JOB/PRINTING';

export const PACK_RESET_APN_SEARCH_REF = '@JOB_PACK/PACK_RESET_APN_SEARCH_REF';

export const SKIPPING_PRESCRIPTION = '@JOB_PACK/SKIPPING_PRESCRIPTION';
export const SKIPPING_PRESCRIPTION_SUCCESS = '@JOB_PACK/SKIPPING_PRESCRIPTION_SUCCESS';

export const REVERSE_SKIPPED_PRESCRIPTION = '@JOB_PACK/REVERSE_SKIPPED_PRESCRIPTION';
export const REVERSE_SKIPPED_PRESCRIPTION_SUCCESS = '@JOB_PACK/SREVERSE_SKIPPED_PRESCRIPTION_SUCCESS';

export const CLEAR_PRESCRIPTION = '@JOB_PACK/CLEAR_PRESCRIPTION';

export const ASSIGN_APN = '@JOB_PACK/ASSIGN_APN';
export const ASSIGN_APN_SUCCESS = '@JOB_PACK/ASSIGN_APN_SUCCESS';
export const ASSIGN_APN_FAIL = '@JOB_PACK/ASSIGN_APN_FAIL';

export function getDoseAllocated(jobIds, sort, includeDeleted = false) {
  return (dispatch) => {
    dispatch({ type: PACK_GET_DOSE });
    HttpService.post(`/api/packjobs/packing`, {
      ids: jobIds,
      sortField: sort?.field,
      sortOrder: sort?.order,
      includeDeleted: includeDeleted,
    })
      .then((resp) => {
        dispatch({
          type: PACK_GET_DOSE_SUCCESS,
          payload: { data: resp.data },
        });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
        dispatch({ type: PACK_GET_DOSE_FAIL, payload: { message: errorMessages.join('\n') } });
      });
  };
}

export function showPrescription(prescriptionId, isCytotoxic) {
  return (dispatch) => {
    dispatch({ type: PACK_SHOW_PRESCRIPTION, payload: { prescriptionId, isCytotoxic } });
  };
}

export const resetAPNSearchRef = () => {
	return dispatch => {
		dispatch({ type: PACK_RESET_APN_SEARCH_REF });
	};
};

export function searchByAPN(jobIds, drugApn, isCytotoxic) {
  return (dispatch, getState) => {
    const { prescriptions: prescriptionList } = getState().job.pack.dose;
    dispatch({ type: PACK_SEARCH_BY_APN });
    return HttpService.post('/api/packjobs/prescriptions/apn', { jobIds, drugApn }, { resolveStatus400: true }).then(
      (resp) => {
        if (resp.isSuccess) {
          if (resp.data?.prescriptionIds?.length !== 0 && !resp.data?.isAlternativeSameGenericCode) {
            const firstPrescriptionIds =
              // Match 1 medication
              dispatch({
                type: PACK_SEARCH_BY_APN_SUCCESS,
                // get single prescription id follow ticket NG-5578
                payload: {
                  data: {
                    ...resp.data,
                    prescriptionIds: [
                      prescriptionList.find((i) => resp.data?.prescriptionIds.includes(i.originalPrescriptionId))
                        .originalPrescriptionId,
                    ],
                  },
                  isCytotoxic,
                },
              });
          } else if (resp.data?.prescriptionIds?.length === 0 && !resp.data?.isAlternativeSameGenericCode) {
            // Do not match any medication
            dispatch(
              AppActions.createDialogMessage(
                'error',
                'job:SEARCH_APN_MESSAGE_NOT_MATCH_TITLE',
                'job:SEARCH_APN_MESSAGE_NOT_MATCH_MESSAGE',
                { options: { buttons: [{ label: i18n.t('BUTTON_OK'), color: 'secondary' }] } },
              ),
            );
            dispatch({ type: PACK_SEARCH_BY_APN_SUCCESS });
          } else if (resp.data?.prescriptionIds?.length !== 0 && resp.data?.isAlternativeSameGenericCode) {
            // Scanned barcode is not matched in current job but found medication have same barcode
            dispatch(
              AppActions.createDialogMessage(
                'error',
                'job:SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE',
                {
                  message: 'job:SEARCH_APN_MESSAGE_SAME_GENERIC_MESSAGE',
                  options: {
                    code: resp.data?.scannedDrug?.drugCode || '',
                    medication: resp.data?.scannedDrug?.drugName || '',
                  },
                },
                {
                  dialogId: 'SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE',
                  options: {
                    buttons: [
                      {
                        label: i18n.t('NO'),
                      },
                      {
                        label: i18n.t('YES'),
                        color: 'primary',
                        variant: 'contained',
                      },
                    ],
                  },
                  callback: (label) => {
                    if (label === i18n.t('YES')) {
                      dispatch({
                        type: PACK_SEARCH_BY_APN_SUCCESS,
                        payload: {
                          data: resp.data,
                          isCytotoxic,
                        },
                      });
                    } else {
                      dispatch(resetAPNSearchRef());
                    }
                    dispatch(AppActions.closeDialogMessage('SEARCH_APN_MESSAGE_SAME_GENERIC_TITLE'));
                  },
                },
              ),
            );
            dispatch({ type: PACK_SEARCH_BY_APN_SUCCESS });
          } else {
            dispatch({ type: PACK_SEARCH_BY_APN_SUCCESS });
          }
        } else {
          dispatch({ type: PACK_SEARCH_BY_APN_SUCCESS });
          return { ...resp, failItem: drugApn };
        }

        return resp;
      },
    );
    // .catch((err) => {
    //   const { errorMessages } = parseError(err);
    //   const error = { errorMessages, failItem: drugApn };
    //   // dispatch(
    //   //   AppActions.createDialogMessage(
    //   //     'error',
    //   //     'job:SEARCH_APN_MESSAGE_NOT_FOUND_TITLE',
    //   //     'job:SEARCH_APN_MESSAGE_NOT_FOUND_MESSAGE',
    //   //     { options: { buttons: [{ label: i18n.t('BUTTON_OK'), color: 'secondary' }] } },
    //   //   ),
    //   // );
    //   dispatch({
    //     type: PACK_SEARCH_BY_APN_FAIL,
    //     payload: { message: i18n.t('job:SEARCH_APN_MESSAGE_NOT_FOUND_MESSAGE') },
    //   });
    //   throw error;
    // });
  };
}

export function assignAPN(drugId, drugApn) {
  return (dispatch) => {
    dispatch({ type: ASSIGN_APN });
    return HttpService.post(`api/drugs/${drugId}/apn`, { drugApn })
      .then(() => {
        dispatch(
          AppActions.showMessage({
            message: 'Drug Barcode has been added successfully',
          }),
        );
        dispatch({ type: ASSIGN_APN_SUCCESS });
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch({ type: ASSIGN_APN_FAIL });
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function updateMatchedAPNId(id = null) {
	return dispatch => {
		dispatch({ type: PACK_UPDATE_MATCH_APN_ID, payload: { id } });
	};
}

export function recordVPB(jobIds, data) {
	return dispatch => {
		dispatch({ type: PACK_RECORD_VPB });
		return HttpService.put(`/api/packjobs/complete-packing`, { jobIds, prescriptions: data })
			.then(() => {
				dispatch({ type: PACK_RECORD_VPB_SUCCESS });
				dispatch(
					AppActions.showMessage({
						message: 'job:RECORD_VPB_SUCCESS_MESSAGE',
						autoHideDuration: 4000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right'
						},
						variant: 'success'
					})
				);
			})
			.catch(err => {
				const { errorMessages, errorTitle } = parseError(err);
				dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
				dispatch({ type: PACK_RECORD_VPB_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export function resetData() {
	return dispatch => {
		dispatch({ type: PACK_RESET_DATA });
	};
}

export function getPrinterSetting(clientCode, foilPackingType) {
	return dispatch => {
		dispatch({ type: PACK_GET_PRINTER_SETTING });
		HttpService.get(`/api/printer-settings/client-code/${clientCode}/pack-type/${foilPackingType}`)
			.then(resp => {
				dispatch({ type: PACK_GET_PRINTER_SETTING_SUCCESS, payload: { data: resp.data?.defaultPrinter } });
			})
			.catch(err => {
				const { errorMessages } = parseError(err);
				dispatch({ type: PACK_GET_PRINTER_SETTING_FAIL, payload: { message: errorMessages.join('\n') } });
			});
	};
}

export const printing = value => {
	return dispatch => {
		dispatch({ type: PRINTING, payload: { printing: value } });
	};
};

export const confirmPrescription = (jobIds, prescriptions, isPackingConfirmed, isManually) => {
  return (dispatch) => {
    dispatch({ type: PACK_CONFIRM_PRESCRIPTION });
    return HttpService.put(`/api/packjobs/packing-confirm`, {
      jobIds,
      prescriptions: prescriptions.map((p) => ({
        prescriptionId: p.prescriptionId,
        packingQty: toNumber(p.qty),
        isQtyChanged: p.isQtyChanged,
        isPackingConfirm: isPackingConfirmed,
        isManually,
        packedDrugId: p?.drug.packedDrugId || p?.drug.id,
        packedDrugCode: p?.drug.packedDrugCode || p?.drug.drugCode,
        packedDrugName: p?.drug.packedDrugName || p?.drug.drugName,
      })),
    })
      .then(() => {
        dispatch({
          type: PACK_CONFIRM_PRESCRIPTION_SUCCESS,
          payload: {
            prescriptionIds: prescriptions.map((p) => p.prescriptionId),
            isPackingConfirmed,
            prescriptions: prescriptions.map((p) => ({
              ...p,
              prescriptionId: p.prescriptionId,
              packingQty: toNumber(p.qty),
              isQtyChanged: p.isQtyChanged,
              isPackingConfirm: isPackingConfirmed,
              isManually,
              packedDrugId: p?.drug.packedDrugId || p?.drug.id,
              packedDrugCode: p?.drug.packedDrugCode || p?.drug.drugCode,
              packedDrugName: p?.drug.packedDrugName || p?.drug.drugName,
            })),
          },
        });
        return true;
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
        dispatch({ type: PACK_CONFIRM_PRESCRIPTION_FAIL, payload: { message: errorMessages.join('\n') } });
        return false;
      });
  };
};

export const skippingPrescription = (skipModal, jobIds) => {
  const _modal = {
    prescriptionIds: [skipModal.prescriptionId],
    packingQty: 0,
    isSkip: true,
  };
  return (dispatch) => {
    dispatch({ type: SKIPPING_PRESCRIPTION });
    return HttpService.put('api/packjobs/packing-skip', skipModal).then(() => {
      dispatch({ type: SKIPPING_PRESCRIPTION_SUCCESS, payload: { ..._modal } });
      dispatch(getDoseAllocated(jobIds));
      dispatch(clearPrescription());
    });
  };
};

export const reverseSkippedPrescription = (reverseSkippedModel, jobIds) => {
  return (dispatch) => {
    dispatch({ type: REVERSE_SKIPPED_PRESCRIPTION });
    return HttpService.put('api/packjobs/packing-reverse-skipped', reverseSkippedModel).then(() => {
      dispatch({ type: REVERSE_SKIPPED_PRESCRIPTION_SUCCESS });
      dispatch(getDoseAllocated(jobIds));
      dispatch(clearPrescription());
    });
  };
};

const clearPrescription = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PRESCRIPTION });
  };
};
