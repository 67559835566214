import { AuthService, HttpService } from 'app/services';
import { groupBy, toNumber } from 'lodash';
import * as AppActions from 'app/store/actions';
import * as ConfigurationActions from 'app/main/pharmacy/store/actions';
import * as UserActions from './user.actions';
import * as NavbarActions from '../../../store/actions/fuse/navbar.actions';
import { parseError } from '../../../../helpers/Error';

export const LOGIN_START = '@LOGIN/LOGIN_START';
export const LOGIN_END = '@LOGIN/LOGIN_END';
export const LOGIN_ERROR = '@LOGIN/LOGIN_ERROR';
export const LOGIN_SUCCESS = '@LOGIN/LOGIN_SUCCESS';

export const GET_PROFILE = '@LOGIN/GET_PROFILE';
export const GET_PROFILE_SUCCESS = '@LOGIN/GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = '@LOGIN/GET_PROFILE_FAIL';

export function submitLogin({ username, password }) {
	return dispatch => {
		dispatch({ type: LOGIN_START });
		HttpService.post('/api/token', { username, password })
			.then(data => {
				// set store container
				// if (remember) {
				// 	AuthService.setStore(localStorage);
				// } else {
				// 	AuthService.setStore(sessionStorage);
				// }

				AuthService.setStore(localStorage);

				dispatch({ type: LOGIN_SUCCESS });

        AuthService.setToken(data.token);
        AuthService.setRefreshToken(data.refreshToken);
        AuthService.emit('loginSuccess', data.triggerChangePassword); // emit login success event to Auth component
        window.localStorage.setItem('isLogined', true); // Sharing login state between multiple tab
        // window.localStorage.setItem('lastPing', Date.now());
        dispatch({ type: LOGIN_END });
      })
      .catch((e) => {
        AuthService.clearToken();
        const { errorMessages } = parseError(e);
        dispatch(AppActions.createDialogMessage('error', 'login:LOGIN_FAIL_DIALOG_TITLE', errorMessages));
        dispatch({ type: LOGIN_ERROR, payload: errorMessages.join('\n') });
        dispatch({ type: LOGIN_END });
      });
  };
}

export function getUserProfile() {
	return dispatch =>
		new Promise(resolve => {
			dispatch({ type: GET_PROFILE });
			HttpService.get('/api/users/profile')
				.then(({ data }) => {
					dispatch({ type: GET_PROFILE_SUCCESS });
					dispatch(UserActions.setRole(['user']));
					dispatch(
						UserActions.setUserData({
							displayName: `${data.firstName} ${data.lastName}`,
							firstName: data.firstName,
							lastName: data.lastName,
							photoURL: data.avatarId,
							userName: data.userName,
							email: data.email,
							currentPharmacyId: data.currentPharmacyId,
							currentPharmacyName: data.currentPharmacyName,
							currentTimezone: data.timezone,
							id: data.id,
							isSuperAdmin: data.isSuperAdmin,
							dob: data.dob,
							roles: data.roles,
							gender: data.gender,
							isChangeCommentRequired: data.isChangeCommentRequired,
							roleLevel: data.roleLevel
						})
					);

          const permissionObject = groupBy(data.permissions, 'key');
          const permission = Object.keys(permissionObject).reduce((rs, key) => {
            return {
              ...rs,
              [key]: {
                c: permissionObject[key].some((i) => i.value[0]),
                r: permissionObject[key].some((i) => i.value[1]),
                u: permissionObject[key].some((i) => i.value[2]),
                d: permissionObject[key].some((i) => i.value[3]),
              },
            };
          }, {});
          dispatch(UserActions.setPermission(permission));
          if (data.currentPharmacyId !== null) {
            HttpService.get(`api/pharmacies/${data.currentPharmacyId}`).then((resp) => {
              dispatch(UserActions.setSyncStatus(resp.data.dispenseSyncStatus, resp.data.dispenseSyncVersion));
              dispatch(UserActions.setDispensingSystem(resp.data.dispensingSystem));
              dispatch(ConfigurationActions.setPharmacyConfig(resp.data));
              dispatch(ConfigurationActions.getPharmacyConfiguration(data.currentPharmacyId));
            });

          HttpService.get(`api/helpdesk/server-information`).then((resp) => {
            dispatch(NavbarActions.setIpAddress(resp.data.ipAddress));
          });
            return { currentPharmacyId: data.currentPharmacyId, currentPharmacyStatus: data.currentPharmacyStatus };
          }
          return { currentPharmacyId: null, currentPharmacyStatus: null };
        })
        .then(({ currentPharmacyId, currentPharmacyStatus }) => {
          if (toNumber(currentPharmacyStatus) === 1) return;
          if (!currentPharmacyId) return;
          HttpService.put(`/api/pharmacies/${currentPharmacyId}/default-data`);
        })
        .catch(() => {
          dispatch({ type: GET_PROFILE_FAIL });
          dispatch(
            AppActions.createDialogMessage(
              'error',
              'login:GET_PROFILE_FAIL_DIALOG_TITLE',
              'login:GET_PROFILE_FAIL_DIALOG_MESSAGE',
            ),
          );
          dispatch(UserActions.logoutUser()); // log out when failed to get profile
        })
        .finally(() => resolve());
    });
}
