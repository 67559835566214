import * as Actions from '../../actions/fuse';

const initialState = {
	foldedOpen: false,
	mobileOpen: false,
	subNavOpen: null,
	collapseNav: false,
	ipAddress: '',
};

const navbar = (state = initialState, action) => {
  switch (action.type) {
    case Actions.TOGGLE_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: !state.foldedOpen,
      };
    }
    case Actions.OPEN_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: true,
      };
    }
    case Actions.CLOSE_FOLDED_NAVBAR: {
      return {
        ...state,
        foldedOpen: false,
      };
    }
    case Actions.OPEN_SUB_NAVBAR: {
      return {
        ...state,
        subNavOpen: action.payload,
      };
    }
    case Actions.CLOSE_SUB_NAVBAR: {
      return {
        ...state,
        subNavOpen: null,
      };
    }
    case Actions.COLLAPSE_NAVBAR:
      return {
        ...state,
        collapseNav: true,
      };
    case Actions.TOGGLE_NAVBAR:
      return {
        ...state,
        collapseNav: false,
      };
    case Actions.TOGGLE_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: !state.mobileOpen,
      };
    }
    case Actions.OPEN_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: true,
      };
    }
    case Actions.CLOSE_MOBILE_NAVBAR: {
      return {
        ...state,
        mobileOpen: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default navbar;
