import { HttpService } from 'app/services';
import * as AppActions from 'app/store/actions';
import { parseError } from 'helpers/Error';

export const OPEN_FOLDED_NAVBAR = '[NAVBAR] OPEN FOLDED';
export const CLOSE_FOLDED_NAVBAR = '[NAVBAR] CLOSE FOLDED';
export const TOGGLE_FOLDED_NAVBAR = '[NAVBAR] TOGGLE FOLDED';
export const OPEN_SUB_NAVBAR = '[NAVBAR] OPEN SUB NAVBAR';
export const CLOSE_SUB_NAVBAR = '[NAVBAR] CLOSE SUB NAVBAR';
export const TOGGLE_MOBILE_NAVBAR = '[NAVBAR] TOGGLE MOBILE';
export const OPEN_MOBILE_NAVBAR = '[NAVBAR] OPEN MOBILE';
export const CLOSE_MOBILE_NAVBAR = '[NAVBAR] CLOSE MOBILE';
export const COLLAPSE_SUBNAV_OPEN = '[NAVBAR] COLLAPSE SUBNAV OPEN';
export const COLLAPSE_SUBNAV_CLOSE = '[NAVBAR] SHOW SUBNAV CLOSE';
export const COLLAPSE_NAVBAR = '[NAVBAR] COLLAPSE MENU';
export const TOGGLE_NAVBAR = '[NAVBAR] TOGGLE MENU';
export const IS_COLLAPSE_NAVBAR = '[NAVBAR] IS COllAPSE MENU';
export const SET_IP_ADDRESS = '[NAVBAR]_SET_IP_ADDRESS';

export function navbarToggleFolded() {
	return {
		type: TOGGLE_FOLDED_NAVBAR
	};
}

export function navbarOpenFolded() {
	return {
		type: OPEN_FOLDED_NAVBAR
	};
}

export function navbarCloseFolded() {
	return {
		type: CLOSE_FOLDED_NAVBAR
	};
}

export function navbarOpenSub(id) {
	return {
		type: OPEN_SUB_NAVBAR,
		payload: id
	};
}

export function navbarCloseSub() {
	return {
		type: CLOSE_SUB_NAVBAR
	};
}

export function getCollapseNavbar() {
  return (dispatch, getState) => {
    const { currentPharmacyId: pharmacyId } = getState().auth.user.data;
    if (!pharmacyId)
      return new Promise((resolve) => {
        resolve({ data: { isCollapseMenuBar: false } });
      });
    return HttpService.get(`api/pharmacies/${pharmacyId}/collapse-menu-bar`)
      .then((resp) => {
        if (resp?.data.isCollpseMenuBar) {
          dispatch({ type: COLLAPSE_NAVBAR });
        } else {
          dispatch({ type: TOGGLE_NAVBAR });
        }
        return resp;
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function onToggleNavbar(isToggle) {
  return (dispatch, getState) => {
    const { currentPharmacyId } = getState().auth.user.data;
    HttpService.put(`api/pharmacies/${currentPharmacyId}/collapse-menu-bar`, { isCollpseMenuBar: isToggle })
      .then(() => {
        if (isToggle) {
          dispatch({ type: COLLAPSE_NAVBAR });
        } else {
          dispatch({ type: TOGGLE_NAVBAR });
        }
      })
      .catch((err) => {
        const { errorMessages, errorTitle } = parseError(err);
        dispatch(AppActions.createDialogMessage('error', errorTitle, errorMessages));
      });
  };
}

export function navbarToggleMobile() {
	return {
		type: TOGGLE_MOBILE_NAVBAR
	};
}

export function navbarOpenMobile() {
	return {
		type: OPEN_MOBILE_NAVBAR
	};
}

export function navbarCloseMobile() {
	return {
		type: CLOSE_MOBILE_NAVBAR
	};
}

export function setIpAddress(ipAddress) {
	return {
		type: SET_IP_ADDRESS,
		payload: ipAddress
	};
}

// export function getIsCollapseMenu() {
//   return (dispatch, getState) {
//     const { currentPharmacyId } = getState().auth.user.data;
//     return HttpService.put(`api/pharmacies/${currentPharmacyId}/collapse-menu-bar`, { IsCollpseMenuBar: true }).then(
//       (resp) => {
//         return { type: IS_COLLAPSE_NAVBAR, payload: resp.isCollpseMenuBar };
//       },
//     );
//   }
// }
